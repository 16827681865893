import React, { useState, useEffect } from "react";
import ProgressFormReadOnly from "./re_registration_sub_read_only/ProgressFormReadOnly";
import ReSubmissionReadOnly from "./re_registration_sub_read_only/ReSubmissionReadOnly";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../../services/ReRegistrationService";
import Select from "react-select";

function SchoolOfArchitecture(props) {
  const {
    reRegSubject,
    registration,
    subjectReSubReadOnly,
    subjectReDoReadOnly,
    nextOption
  } = props;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [continueChecked, setContinueChecked] = useState(true);

  const [parentSubjectProgLength, setparentSubjectProgLength] = useState([
    { subject: "" },
  ]);

  const [parentSubjectReSubLength, setparentSubjectReSubLength] = useState([
    { subject: "" },
  ]);

  const handleCheckContinue = (e) => {
    setContinueChecked(e.target.checked);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // subjectProgress_: parentSubjectProgLength,
      subjectReSub_:
        subjectReSubReadOnly && subjectReSubReadOnly.length > 0
          ? subjectReSubReadOnly
          : subjectReDoReadOnly,
      term_condn: checked,
      term_continue: continueChecked,
      next_year_option: nextOption,
      next_year_option_label:nextOption
    },
    validationSchema: Yup.object({
      // subjectProgress_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      subjectReSub_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
      term_continue: Yup.bool().oneOf([true], "Required"),
    }),
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You'r Registration have been submited succefully",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Re-register Modules:</h5>
                <p><b>Important:</b> All failed modules must be completed in 2024. Failing to re-register and complete the outstanding modules will<br/>
                  result in forfeiture of your current progress in the qualification and you will be required to complete a new registration for<br/>
                  the entire course.
                  </p>
                <label>
                  Modules you did not pass and must re-register for.
                </label>
                <ReSubmissionReadOnly
                  formik={formik}
                  setparentSubjectReSubLength={setparentSubjectReSubLength}
                  parentSubjectReSubLength={parentSubjectReSubLength}
                  reRegSubject={reRegSubject}
                />
              </div>
              <div className="form-group col-sm-12 inputfield">
               {/* <h5 className="textdanger heading-5">Progress:</h5>
                {/* <label>
                  <b>Architecture:</b> In order to be eligible to progress into
                  the second year of the National Diploma (NQF6), you need to
                  achieve 50% or more for the following core subjects:
                </label>

                <ul className="list-unstyled unstyledlist-custom">
                  <li>Architectural Design.</li>
                  <li>Construction, Technology and Detailing.</li>
                  <li>Architectural Representation and Draughting.</li>
                  <li>Computer Applications.</li>
                </ul> 

                <label>
                  <b>
                    SSD / Management of Civil Engineering Construction
                    Processes:
                  </b>{" "}
                  In order to be eligible to progress into the second year of
                  SSD or Management of Civil Engineering Construction Processes,
                  please ensure you have 65% across all your modules.
                </label>
              </div>

              <div className="col-12 form-group inputfield">
                 <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_continue &&
                      formik.errors.term_continue
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_continue"
                    onChange={(e) => handleCheckContinue(e)}
                    onBlur={formik.handleBlur}
                    checked={continueChecked}
                    id="customCheck1"
                    disabled="disabled"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    I would like to progress to next level.
                  </label>
                  {formik.touched.term_continue &&
                  formik.errors.term_continue ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_continue}
                    </div>
                  ) : null}
                </div>
                <br />


                {/* <div className="form-group select-width">
                  <label>Please select an option*</label>
                  <div className="form-icon-group">
                    <Select
                      className={
                        "form-control custom-select-box disable-select" +
                        (formik.errors.next_year_option && formik.touched.next_year_option
                          ? " is-invalid"
                          : "")
                      }
                      isDisabled={true}
                      name="next_year_option"
                      value={formik.values.next_year_option}
                      onChange={(value) => {
                        formik.setFieldValue("next_year_option", value.value)
                        formik.setFieldValue("next_year_option_label", value.label);
                      }}
                      onBlur={formik.handleBlur}
                      maxMenuHeight={175}
                      placeholder={formik.values.next_year_option_label ? formik.values.next_year_option_label : "Please select"}
                      options={[
                        { value: 'Progress to Civil Management Processes 2nd Year', label: 'Progress to Civil Management Processes 2nd Year' },
                        // { value: 'Progress to SSD 2nd Year', label: 'Progress to SSD 2nd Year' },
                        { value: 'Progress to Architectural Technology NQF6', label: 'Progress to Architectural Technology NQF6' },
                        { value: 'Progress to (NEW) Higher Certificate in Architectural Technology', label: 'Progress to (NEW) Higher Certificate in Architectural Technology' }
                      ]}
                    />
                    {formik.errors.next_year_option &&
                      formik.touched.next_year_option ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div> */}


                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={formik.handleBlur}
                    checked={checked}
                    id="customCheck2"
                    disabled="disabled"
                  />
                  <label
                    className="custom-control-label color-red"
                    htmlFor="customCheck2"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://aie.ac/page/1_Terms%2520and%2520Conditions' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submitted"
              className="btn btn-submit btn-main btn-primary"
              type="submit"
              disabled="disabled"
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submitted
            </button>
            {/* <button
              className="btn btn-submit btn-main btn-danger ml-2"
              disabled="disabled"
            >
              Cancel
            </button> */}
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfArchitecture;
