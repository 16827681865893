import React from "react";
import { connect } from "react-redux";
import { setUnreadNotificationCount, setWidget, unsetWidget } from "../store/actions";
import {shortenText}  from '../utils/commonFunction.js'
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import $ from 'jquery';
import GlobalCalendar from "../components/course/GlobalCalendar";
import SekletonSubjectListCalendar from "../loaders/SekletonSubjectListCalendar";
import {
  GetSubject, UpdateStudentTimeZone
} from "../services/CalendarService";
import { Scrollbar } from "react-scrollbars-custom";
const stc = require('string-to-color');
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUnreadNotificationCount: (payload) => dispatch(setUnreadNotificationCount(payload)),
    setWidget: (payload) => dispatch(setWidget(payload)),
    unsetWidget: (payload) => dispatch(unsetWidget())
  };
}

/** map state */
const mapStateToProps = (state) => ({ notification: state.notification, user: state.user, widget: state.widget });

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      subjectId: [],
      activeSubject:[],
      //showAll:true,
      subjectListLoading:false
    };
    this.updateSubject = this.updateSubject.bind(this);

  }

  componentDidMount() {
    this.setState({subjectListLoading:true });
    GetSubject()
    .then((res) => {
      this.setState({ subjects: res.data,activeSubject : res.data, subjectListLoading:false });
      this.updateAllSubject();
    })
    .catch((error) => {
      this.setState({ subjectListLoading:false });
    });

    UpdateStudentTimeZone({
      timezone:Intl.DateTimeFormat().resolvedOptions().timeZone
    }).then((res)=>{
      if(res.status===200){   
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  updateSubject = (e) => {
    let sid=Number(e.currentTarget.value);
    if(this.state.subjectId.includes(sid)){
      let index = this.state.subjectId.indexOf(sid);
    if (index > -1) {
      this.state.subjectId.splice(index, 1);
    }
    }else{
      this.state.subjectId.push(sid);
    }
    this.setState({subjectId:this.state.subjectId})
  };

  updateAllSubject = () => {
    const { subjects, subjectId } = this.state;
  
    const activeAllSubject = subjects.filter((data) => data?.subjectStatus !== 0);
  
    let updatedSubjectId = [];
  
    if (subjectId.length > 0) {
      updatedSubjectId = []; 
    } else {
     
      updatedSubjectId = subjects
        .filter((row) => row.subjectStatus !== 0)
        .map((row) => Number(row.id));
    }

    this.setState({
      subjectId: updatedSubjectId,
      activeSubject: activeAllSubject,
    });
  };
  

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="calendar-wrap">
              <div className="event-calendar-main d-flex h-100">
                <div className="event-calendar-left bg-white">
                  <button className="close-btn-sidebar" onClick={this.handleSideBar}>
                    <i className="close-bar close-bar-1"></i>
                    <i className="close-bar close-bar-2"></i>
                    <i className="close-bar close-bar-3"></i>
                  </button>
                  <div className="calendar-panel-main">
                    <div className="calendar-panel-title">
                      <div className="calendar-panel-left">
                      <span><a href="">Show/Hide Subjects</a></span>
                      </div>
                      <div className="calendar-panel-right">
                        <div className="custom-control custom-checkbox text-left box-tm">
                        <label className={`toggleSwitch Switchcalendar`}>
                          <div class="show-all">Show All</div>
                              <input type="checkbox" className="custom-control-input" id="customCheck1"  value='all' onClick={()=> this.updateAllSubject()} checked={this.state.subjectId.length==this.state.activeSubject.length ? 'checked' :'' } />
                              <span>
                                <span><i class="fal fa-eye-slash"></i></span>
                                <span><i class="fal fa-eye"></i></span>
                              </span>
                              <a></a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="calendar-panel-body">
                    <Scrollbar>
                    {this.state.subjectListLoading && <SekletonSubjectListCalendar/>}                    
                    {!this.state.subjectListLoading && this.state.subjects.length>0 && this.state.subjects.map((row) => {
                     
                      let stylesBorder = {
                        'color': stc(row.name)
                      };
                      let styles = {
                        'background-color': stc(row.name)
                      };
                    return(
                  
                    <div class={`calendar-cart-item`}>
                     <div class={`calendar-cart-item-inner ${row?.subjectStatus == 0 && "block-course-div"}`} style={stylesBorder}>
                       <button>{shortenText(row.name.trim(),30)} </button>
                      <span style={styles}  className="calendar-checkbox">
                        <input
                          type="checkbox"
                          value={row.id}
                          onClick={(e) => this.updateSubject(e)}
                          checked={row?.subjectStatus !== 0 && this.state.subjectId.includes(row.id)}
                          disabled={row?.subjectStatus == 0}
                        />       
                      </span>
                     </div>
                   </div>
                    )
                    })}
                   
                    </Scrollbar>
                    </div>
                  </div>
                </div>
                <div className="event-calendar-right">
                {this.state.subjectId.length<1 && !this.state.subjectListLoading ? (
                  <div className="subject-no-content">
                  <div className="no-img">
                    {/* <img src={not_yet_published_icon} /> */}
                  </div>
                  <div className="no-content">
                    <h4>No Subject Selected</h4>
                    <p>Please select any subject</p>
                  </div>
                </div>
                ):(
                  <GlobalCalendar subjectId={this.state.subjectId}></GlobalCalendar>
                )
                } 
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
